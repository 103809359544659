<template>
  <div class="has-nav-bar">
    <van-nav-bar
      :border="false"
      class="inspect-nav"
      left-arrow
      fixed
      @click-left="$router.back()"
    >
      <div slot="title" class="nav-title">健康检查</div>
    </van-nav-bar>

    <LoadView v-model="loadStatus" style="width: 100%;">
      <van-form ref="form" class="content-box" validate-trigger="onSubmit">
        <!-- 检查日期 -->
        <van-field label="检查日期:">
          <template #input>
            {{ form.checkDate }}
          </template>
        </van-field>

        <!-- 检查种类 -->
        <van-field label="检查种类:">
          <template #input>
            {{ form.checkTypeName }}
          </template>
        </van-field>

        <!-- 检查结论 -->
        <van-field label="检查结论:">
          <template #input>
            {{ form.checkResultName }}
          </template>
        </van-field>

        <!-- 检查机构 -->
        <van-field label="检查机构:">
          <template #input>
            {{ form.checkOrg }}
          </template>
        </van-field>

        <!-- 人员处理情况 -->
        <van-field label="人员处理情况:">
          <template #input>
            {{ form.personHandleResultName }}
          </template>
        </van-field>

        <!-- 附件 -->
        <van-field label="附件:">
          <template #input>
            <FileUploader
              :value="
                form.attachmentId
                  ? {
                      name: form.attachmentName,
                      id: form.attachmentId,
                      null: false
                    }
                  : []
              "
              :max-count="1"
              unable-upload
            />
          </template>
        </van-field>

        <!-- 签名 -->
        <van-field v-if="form.signStatus === 1" label="签名:">
          <template #input>
            <Tag type="green" tick @click.native="showSignImage">
              {{ form.userName }}
            </Tag>
          </template>
        </van-field>
      </van-form>
    </LoadView>

    <BottomFixedBox v-if="form.signStatus === 0">
      <van-button
        class="confirm-btn"
        round
        size="small"
        type="info"
        @click="sign()"
        >签名</van-button
      >
    </BottomFixedBox>
  </div>
</template>

<script>
import LoadView from "@/components/loadview/LoadView";
import FileUploader from "@/components/fileuploader/FileUploader";
import BottomFixedBox from "@/components/general/BottomFixedBox";
import Tag from "@/components/Tag";
import { LOAD_STATUS } from "@/components/loadview/utils";
import esign from "@/components/esign";
import { fileUrl } from "@/utils/constant";

import { getInspectListDetail, professionCheckSignature } from "./api";
export default {
  components: {
    LoadView,
    FileUploader,
    BottomFixedBox,
    Tag
  },
  data() {
    return {
      loadStatus: LOAD_STATUS.LOADING,
      form: {}
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      getInspectListDetail(this.$route.params.id)
        .then(res => {
          if (!res) {
            this.loadStatus = LOAD_STATUS.EMPTY;
            return;
          }
          this.form = res;
          this.loadStatus = LOAD_STATUS.SUCCESS;
        })
        .catch(err => {
          console.log("err", err);
          this.loadStatus = LOAD_STATUS.ERROR;
        });
    },
    sign() {
      esign.show(imgRes => {
        professionCheckSignature({
          id: this.$route.params.id,
          fileId: imgRes.id
        }).then(() => {
          this.$router.back();
        });
      });
    },
    showSignImage() {
      esign.showImage(fileUrl + this.form.fileId);
    }
  }
};
</script>
