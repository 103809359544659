<template>
  <div>
    <van-nav-bar
      title="劳保发放"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    />

    <LoadView v-model="loadStatus" style="width: 100%;">
      <van-checkbox-group v-show="list.length" v-model="value">
        <div v-for="item in list" :key="item.id" class="labor-box">
          <van-checkbox shape="square" :name="item.id" />
          <div class="labor-item">
            <div>
              <span class="labor-title">{{ item.issueDate }}</span>
              <span class="labor-tag">{{ item.protectionName }}</span>
            </div>
            <div class="labor-text">
              {{ item.labourName }}({{ item.issueCount }})
            </div>
          </div>
        </div>
      </van-checkbox-group>
    </LoadView>
    <BottomFixedBox>
      <div class="labor-footer">
        <van-checkbox
          shape="square"
          style="white-space: nowrap;"
          :value="isAll"
          @input="selectAll"
          >全选</van-checkbox
        >

        <van-button
          class="labor-btn"
          round
          size="small"
          type="info"
          :disabled="!value.length"
          @click="sign()"
          >签名</van-button
        >
      </div>
    </BottomFixedBox>
  </div>
</template>

<script>
import LoadView from "@/components/loadview/LoadView";
import BottomFixedBox from "@/components/general/BottomFixedBox";
import { LOAD_STATUS } from "@/components/loadview/utils";

import { getAllUnsignLaborList, professionLabourSignature } from "./api";
import esign from "@/components/esign";
import { fileUrl } from "@/utils/constant";
export default {
  components: {
    LoadView,
    BottomFixedBox
  },
  data() {
    return {
      loadStatus: LOAD_STATUS.LOADING,
      list: [],
      value: []
    };
  },
  computed: {
    isAll() {
      return this.value.length === this.list.length;
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      getAllUnsignLaborList({ signStatus: 0, personId: this.userInfo.id })
        .then(res => {
          if (!res || !res.length) {
            this.loadStatus = LOAD_STATUS.EMPTY;
            return;
          }
          this.list = res;
          this.loadStatus = LOAD_STATUS.SUCCESS;
        })
        .catch(err => {
          console.log("err", err);
          this.loadStatus = LOAD_STATUS.ERROR;
        });
    },
    selectAll(checked) {
      if (checked) {
        this.value = this.list.map(i => i.id);
      } else {
        this.value = [];
      }
    },
    sign() {
      esign.show(imgRes => {
        professionLabourSignature({
          idList: this.value,
          fileId: imgRes.id
        }).then(() => {
          this.$router.back();
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.labor {
  &-box {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    border-bottom: 8px solid #eceef2;
    background-color: #fff;
  }
  &-item {
    margin-left: 13px;
    flex-grow: 1;
  }
  &-title {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2e2e4d;
    line-height: 20px;
  }
  &-tag {
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 16px;
    background: #0fb8c5;
    border-radius: 2px;
    padding: 2px 5px;
    margin-left: 4px;
  }

  &-text {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a0a2a6;
    line-height: 24px;
  }

  &-footer {
    flex-grow: 1;
    width: 100%;
    display: flex;
    align-items: center;
  }

  &-btn {
    margin-left: 47px;
  }
}
</style>
