<template>
  <div class="has-nav-bar">
    <van-nav-bar
      :border="false"
      class="labor-nav"
      left-arrow
      fixed
      @click-left="$router.back()"
    >
      <div slot="title" class="nav-title">劳保发放</div>
    </van-nav-bar>

    <LoadView v-model="loadStatus" style="width: 100%;">
      <van-form ref="form" class="content-box" validate-trigger="onSubmit">
        <!-- 姓名 -->
        <van-field label="姓名:">
          <template #input>
            {{ form.personName }}
          </template>
        </van-field>

        <!-- 发放日期 -->
        <van-field label="发放日期:">
          <template #input>
            {{ form.issueDate }}
          </template>
        </van-field>

        <!-- 备注 -->
        <van-field label="备注:">
          <template #input>
            {{ form.remark }}
          </template>
        </van-field>

        <!-- 劳保用品名称 -->
        <van-field label="劳保用品名称:">
          <template #input>
            {{ form.labourName }}
          </template>
        </van-field>

        <!-- 数量 -->
        <van-field label="数量:">
          <template #input>
            {{ form.issueCount }}
          </template>
        </van-field>

        <!-- 型号 -->
        <van-field label="型号:">
          <template #input>
            {{ form.modelNum }}
          </template>
        </van-field>

        <!-- 防护类型 -->
        <van-field label="防护类型:">
          <template #input>
            {{ form.protectionName }}
          </template>
        </van-field>

        <!-- 签名 -->
        <van-field v-if="form.signStatus === 1" label="签名:">
          <template #input>
            <Tag type="green" tick @click.native="showSignImage">
              {{ form.personName }}
            </Tag>
          </template>
        </van-field>
      </van-form>
    </LoadView>

    <BottomFixedBox v-if="form.signStatus === 0">
      <van-button
        class="confirm-btn"
        round
        size="small"
        type="info"
        @click="sign()"
        >签名</van-button
      >
    </BottomFixedBox>
  </div>
</template>

<script>
import LoadView from "@/components/loadview/LoadView";
import BottomFixedBox from "@/components/general/BottomFixedBox";
import Tag from "@/components/Tag";
import { LOAD_STATUS } from "@/components/loadview/utils";
import esign from "@/components/esign";
import { fileUrl } from "@/utils/constant";

import { getLaborListDetail, professionLabourSignature } from "./api";
export default {
  components: {
    LoadView,
    BottomFixedBox,
    Tag
  },
  data() {
    return {
      loadStatus: LOAD_STATUS.LOADING,
      form: {}
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      getLaborListDetail(this.$route.params.id)
        .then(res => {
          if (!res) {
            this.loadStatus = LOAD_STATUS.EMPTY;
            return;
          }
          this.form = res;
          this.loadStatus = LOAD_STATUS.SUCCESS;
        })
        .catch(err => {
          console.log("err", err);
          this.loadStatus = LOAD_STATUS.ERROR;
        });
    },
    sign() {
      esign.show(imgRes => {
        professionLabourSignature({
          idList: [this.$route.params.id],
          fileId: imgRes.id
        }).then(() => {
          this.$router.back();
        });
      });
    },
    showSignImage() {
      esign.showImage(fileUrl + this.form.fileId);
    }
  }
};
</script>
