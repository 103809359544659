<template>
  <div>
    <van-nav-bar
      title="健康检查"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    />

    <date-time v-model="dateTime" type="date" format="{y}-{m}-{d}"></date-time>
    <InspectQuery :query="searchData" @refresh="onSearch" />
    <InspectList ref="inspect-list" :api="getInspectList" />
  </div>
</template>

<script>
import InspectQuery from "./components/InspectQuery.vue";
import InspectList from "./components/InspectList.vue";

import { getInspectList } from "./api";
export default {
  components: {
    InspectList,
    InspectQuery
  },
  data() {
    return {
      dateTime: { start: "", end: "" },
      searchData: {
        startDate: "",
        endDate: "",
        checkType: "",
        checkResult: "",
        personHandleResult: "",
        signStatus: ""
      }
    };
  },
  watch: {
    dateTime: {
      handler(val) {
        if (val.start && val.end) {
          this.searchData.startDate = val.start;
          this.searchData.endDate = val.end;
          this.onSearch();
        }
        if (!val.start && !val.end) {
          this.searchData.startDate = "";
          this.searchData.endDate = "";
          this.onSearch();
        }
      },
      deep: true
    }
  },
  methods: {
    getInspectList(query = {}) {
      return getInspectList({ ...this.searchData, ...query });
    },
    onSearch() {
      this.$refs["inspect-list"].getList(true);
    }
  }
};
</script>
