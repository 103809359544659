<template>
  <div>
    <van-nav-bar
      title="劳保发放"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
      <div slot="right" class="nav-right">
        <span @click="$router.push({ name: 'health-labor-batch-sign' })"
          >批量签名</span
        >
      </div>
    </van-nav-bar>

    <date-time v-model="dateTime" type="date" format="{y}-{m}-{d}"></date-time>
    <LaborQuery :query="searchData" @refresh="onSearch" />
    <LaborList ref="labor-list" :api="getLaborList" />
  </div>
</template>

<script>
import LaborQuery from "./components/LaborQuery.vue";
import LaborList from "./components/LaborList.vue";

import { getLaborList } from "./api";
export default {
  components: {
    LaborList,
    LaborQuery
  },
  data() {
    return {
      dateTime: { start: "", end: "" },
      searchData: {
        startDate: "",
        endDate: "",
        labourName: "",
        protectionType: "",
        signStatus: ""
      }
    };
  },
  watch: {
    dateTime: {
      handler(val) {
        if (val.start && val.end) {
          this.searchData.startDate = val.start;
          this.searchData.endDate = val.end;
          this.onSearch();
        }
        if (!val.start && !val.end) {
          this.searchData.startDate = "";
          this.searchData.endDate = "";
          this.onSearch();
        }
      },
      deep: true
    }
  },
  methods: {
    getLaborList(query = {}) {
      return getLaborList({
        ...this.searchData,
        ...query,
        personId: this.userInfo.id
      });
    },
    onSearch() {
      this.$refs["labor-list"].getList(true);
    }
  }
};
</script>
