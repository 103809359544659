<template>
  <LoadListView
    v-model="loadStatus"
    :list="list"
    :total="total"
    show-total-view
    v-bind="$attrs"
    @load="getList"
  >
    <div
      v-for="(item, idx) in list"
      :key="idx"
      class="inspect-item"
      @click="gotoDetails(item)"
    >
      <div class="inspect-left">
        <div>
          <span class="inspect-title">{{ item.checkDate }}</span>
          <span class="inspect-tag">{{ item.checkTypeName }}</span>
        </div>
        <div>
          <span class="inspect-text">{{ item.checkResultName }}</span>
          <span class="inspect-text">{{ item.personHandleResultName }}</span>
        </div>
      </div>
      <div class="inspect-right">
        <van-button
          v-if="item.signStatus"
          class="inspect-btn is-sign"
          type="info"
          size="small"
          round
          @click.prevent.stop="showSignImage(item)"
        >
          已签
        </van-button>
        <van-button
          v-else
          class="inspect-btn"
          type="info"
          size="small"
          round
          @click.prevent.stop="sign(item)"
        >
          签名
        </van-button>
      </div>
    </div>
  </LoadListView>
</template>

<script>
import esign from "@/components/esign";
import { fileUrl } from "@/utils/constant";
import { professionCheckSignature } from "../api";
export default {
  props: {
    api: {
      type: Function,
      default: () => Promise.resolve({ total: 0, list: [] })
    }
  },
  data() {
    return {
      loadStatus: "loading",
      query: {
        size: 10,
        page: 1
      },
      list: [],
      total: 0,
      error: false
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    sign(item) {
      esign.show(imgRes => {
        professionCheckSignature({
          id: item.id,
          fileId: imgRes.id
        }).then(() => {
          this.getList(true);
        });
      });
    },
    showSignImage(item) {
      esign.showImage(fileUrl + item.fileId);
    },
    gotoDetails(item) {
      this.$router.push({
        name: "health-inspect-detail",
        params: { id: item.id }
      });
    },
    getList(refresh = false) {
      if (refresh) {
        this.query.page = 1;
        this.list = [];
        this.total = 0;
        this.loadStatus = "loading";
      }

      this.api(this.query)
        .then(({ list = [], total = 0 }) => {
          this.list.push(...list);
          this.query.page++;
          this.total = total;
          this.loadStatus = "success";
        })
        .catch(error => {
          console.log(error);
          this.loadStatus = "error";
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.inspect {
  &-item {
    padding: 10px 16px;
    border-bottom: 8px solid #eceef2;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-title {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2e2e4d;
    line-height: 20px;
  }
  &-tag {
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 16px;
    background: #0fb8c5;
    border-radius: 2px;
    padding: 2px 5px;
    margin-left: 4px;
  }

  &-text {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a0a2a6;
    line-height: 24px;
    padding: 0 10px;

    &:nth-of-type(1) {
      padding-left: 0;
      border-right: 1px solid #a0a2a6;
    }
  }

  &-right {
    white-space: nowrap;
  }

  &-btn {
    border-radius: 16px;
    padding: 0px 19px;
    height: 28px;

    &.is-sign {
      background: rgba(103, 194, 58, 0.1);
      color: #67c23a;
      border-color: rgba(103, 194, 58, 0.1);
    }
  }
}
</style>
