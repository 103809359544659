import axios from "@/utils/axios";

import apiPath from "@/utils/apiPath";

const { psm } = apiPath;

// 获取筛选选项
export function getProfessionSelect(params) {
  return axios.get(`${psm}/profession/select/type`, { params });
}
// 健康检查列表
export function getInspectList(params) {
  return axios.get(`${psm}/profession/page/app/profession-check`, { params });
}

// 健康检查签名
export function professionCheckSignature(data) {
  return axios.post(`${psm}/profession/add/profession-check/signature`, data);
}

// 健康检查详情
export function getInspectListDetail(id) {
  return axios.get(`${psm}/profession/info/profession-check/${id}`);
}

// 防护类型列表

export function getLabourYnType() {
  return axios.get(`${psm}/select/labour-yn/type`);
}

// 获取劳保用品名称列表
export function getLabourYnLabourName() {
  return axios.get(`${psm}/select/labour-yn/labour/name`);
}

// 劳保发放列表
export function getLaborList(params) {
  return axios.get(`${psm}/page/profession-labour-yn`, { params });
}

// 劳保发放签名
export function professionLabourSignature(data) {
  return axios.post(`${psm}/add/profession-labour-issue-yn/signature`, data);
}

// 劳保发放详情
export function getLaborListDetail(id) {
  return axios.get(`${psm}/info/profession-labour-yn/${id}`);
}

// 获取全部未签名列表
export function getAllUnsignLaborList(params) {
  return axios.get(`${psm}/list/profession-labour-yn`, { params });
}
